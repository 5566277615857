//@flow
import * as React from 'react'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { variant, compose } from 'styled-system'
import { textStyles } from '../text/textStyles'

import { styling, positioning, typography } from '../../helpers'

const defaultStyles = css({
  fontFamily: 0,
  fontWeight: 1,
  fontSize: [0, 0, 1],
  letterSpacing: 1,
  color: 'defaultText.30',
  lineHeight: 0,
})

const statusStyles = variant({
  variants: {
    error: {
      color: 'modes.brick.primaryShades.50',
    },
  },
})

const StyledLabel = styled.label`
  transition: color 0.1s;
  will-change: color;
  ${defaultStyles}
  ${compose(positioning, typography, styling, textStyles, statusStyles)};
`

type LabelProps = {
  status?: 'idle' | 'active' | 'error' | 'success',
  typography?: string,
}

export function Label({ status, typography, ...props }: LabelProps) {
  return <StyledLabel {...{ ...props, typography }} variant={status} />
}

export default Label
