//@flow
import * as React from 'react'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { AddressBox } from '../components/molecules/addressBox'
import { OpeningHours } from '../components/molecules/openingHours'
import { Flex } from '../components/atoms/cards'
import Hero, {
  modelContactHero,
  type ContactHeroData,
} from '../components/organisms/hero'

import { FlexColumnGrid, InnerPageContainer } from '../components/containers'
import ContactForm from '../components/organisms/contactForm'
import { Button } from '../components/atoms/buttons/button-new/button'

import { type PageMeta } from '../components/containers/seoData'

export type ContactData = {
  prismic: {
    contact: {
      ...ContactHeroData,
      seo_data: PageMeta[],
      _meta: {
        uid: string,
      },
    },
  },
}

function ContactUs({ data }: { data: ContactData }): React.Node {
  const {
    _meta: { uid },
    seo_data,
    ...doc
  } = data.prismic.contact
  //$FlowFixMe
  const heroData = modelContactHero(doc)
  if (!doc) return null
  return (
    <Layout path={uid} seoData={seo_data && seo_data.slice(0, 1).pop()}>
      <Helmet
        title="Contact B&W"
        description="Contact us to arrange arrange a survey, estimate or advice on damp, timber and asbestos related concerns."
      />
      <Hero pt={9} pb={9} data={heroData} hideBadge></Hero>
      <InnerPageContainer pt={0} pb={4} fullWidth>
        <FlexColumnGrid
          columns={12}
          flexDirection={['column', 'column', 'column', 'row-reverse']}
          flexWrap={['wrap']}
          px={1}
        >
          <ContactForm
            colSpan={[12, 12, 12, 7]}
            nudge={[0, 0, 0, 0, 1]}
            position="relative"
            bottom={7}
            pt={[5, 5, 6]}
            pb={6}
            px={[2, 4, 6, 5, 6, 8]}
            mx={[0, 0, '6.25%', 0]}
            mb={[-7, -7, -7, -9]}
            name="Contact Form"
          />
          <Flex
            justifyContent={['column', 'column', 'row', 'column']}
            flexWrap="wrap"
            colSpan={[12, 12, 12, 5, 4, 3]}
            nudge={[0, 0, 0, 0]}
            mt={[6]}
            mb={[2]}
            mx={[0, 2, 2, 0, 0]}
            ml={[0, 2, 4, 0]}
            px={[2, 3, 4, 0]}
          >
            <AddressBox
              mr={[0, 0, 6, 0]}
              maxWidth={['100%', '80%', '60%', '80%']}
            />
            <OpeningHours mt={[4, 4, 1, 4]} mr={[0, 4, 0, 2]} flex="1 0 auto" />
            <Flex
              mt={[4, 4, 4, 4]}
              flexDirection={['column', 'column', 'row', 'column']}
              alignItems={['flex-start']}
              flexWrap="wrap"
            >
              <Button
                typography="ui"
                buttonStyle="ghost"
                variant="primary"
                iconVariant="primary"
                focusRing
                focusOffsetX={-2}
                href="tel:+441603860086"
                icon="PHONE"
                px={0}
                mr={[0, 0, 4, 0]}
              >
                01603 860086
              </Button>
              <Button
                typography="ui"
                buttonStyle="ghost"
                variant="primary"
                iconVariant="primary"
                focusRing
                focusOffsetX={-2}
                href="mailto:info@bwpropertycare.co.uk"
                icon="PLANE"
                px={0}
                mt={[1, 1, 0]}
              >
                info@bwpropertycare.co.uk
              </Button>
            </Flex>
          </Flex>
        </FlexColumnGrid>
      </InnerPageContainer>
    </Layout>
  )
}

export const query = graphql`
  query prismicContact {
    prismic {
      contact(lang: "en-gb", uid: "contact") {
        seo_data {
          meta_description
          meta_title
          opengraph_description
          opengraph_image
          opengraph_locale
          opengraph_site_name
          opengraph_title
          twitter_card_type
          twitter_site
        }
        main_image
        main_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        subtitle
        _meta {
          uid
        }
      }
    }
  }
`
export default ContactUs
