//@flow
import * as React from 'react'
import { Flex, Box } from '../atoms/cards'
import { Text } from '../atoms/text/text-new'
import { Button } from '../atoms/buttons/button-new/button'

type AddressBoxProps = { ...mixed }

export function OpeningHours(props: AddressBoxProps) {
  return (
    <Box {...props}>
      <Text typography="heading5" as="h2">
        Office hours:
      </Text>
      <Box mt={0}>
        <Text typography="paragraph">
          Mon–Fri: 8am–5pm <br />
          Public holidays: Closed
        </Text>
      </Box>
    </Box>
  )
}
