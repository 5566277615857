//@flow
import * as React from 'react'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { variant, compose } from 'styled-system'
import { textStyles } from '../text/textStyles'
import { styling, positioning, typography } from '../../helpers'

const defaultStyles = css({
  width: '100%',
  pt: 2,
  pb: 1,
  pl: 3,
  pr: 5,
  bg: 'base.0',
  borderRadius: 2,
  height: [4, 4, 5],
})

const idleStyles = variant({
  variants: {
    idle: {
      bg: 'base.0',
      borderColor: 'base.30',
    },
    active: {
      bg: 'base.0',
      borderColor: 'base.40',
      color: 'defaultText.0',
    },
    error: {
      bg: 'modes.brick.primaryShades.10',
      color: 'modes.brick.primaryShades.60',
      borderColor: 'modes.brick.primaryShades.40',
    },
    success: {
      bg: 'modes.fern.primaryShades.10',
      color: 'modes.fern.primaryShades.60',
      borderColor: 'modes.fern.primaryShades.40',
      opacity: 0.6,
    },
    disabled: {
      cursor: 'no',
    },
  },
})

const hoverStyles = variant({
  variants: {
    idle: {
      borderColor: 'base.40',
    },
    active: {
      color: 'black',
    },
    error: {
      borderColor: 'modes.brick.primaryShades.50',
    },
    success: {
      borderColor: 'modes.fern.primaryShades.50',
      opacity: 0.7,
    },
  },
})

const focusStyles = variant({
  variants: {
    idle: {
      bg: 'base.0',
      borderColor: 'primaryShades.50',
    },
    active: {
      borderColor: 'primaryShades.60',
      color: 'primaryShades.60',
    },
    error: {
      bg: 'modes.brick.primaryShades.0',
      borderColor: 'modes.brick.primaryShades.50',
    },
    success: {
      bg: 'modes.fern.primaryShades.0',
      borderColor: 'modes.fern.primaryShades.50',
      opacity: 1,
    },
  },
})

const placeholderStyles = variant({
  variants: {
    idle: {
      color: 'defaultText.90',
    },
    error: {
      color: 'modes.brick.primaryShades.40',
    },
    success: {
      color: 'modes.fern.primaryShades.40',
    },
  },
})

const placeholderFocusStyles = variant({
  variants: {
    idle: {
      color: 'base.30',
    },
    active: {
      color: 'base.30',
    },
    error: {
      color: 'modes.brick.primaryShades.30',
    },
    success: {
      color: 'modes.fern.primaryShades.30',
    },
  },
})
const selectionStyles = variant({
  variants: {
    idle: {
      bg: 'primaryShades.20',
    },
    active: {
      bg: 'primaryShades.20',
    },
    error: {
      bg: 'modes.brick.primaryShades.20',
    },
    success: {
      bg: 'modes.fern.primaryShades.20',
    },
  },
})

const disabledStyle = css({
  bg: 'base.10',
  borderColor: 'base.20',
  color: 'base.40',
})

const StyledInput = styled.input`
  display: block;
  box-sizing: border-box;
  vertical-align: baseline;
  border: 2px solid transparent;
  transition: all 0.1s, opacity 0.2s;
  will-change: color, border-color, opacity;
  ${defaultStyles};
  ${compose(
    positioning,
    textStyles,
    typography,
    styling,
    idleStyles
  )}
  &::placeholder {
    transition: color 0.1s;
    will-change: color;
    ${placeholderStyles}
  }
  &:hover {
    ${hoverStyles};
  }
  &:focus {
    outline: none;
    ${focusStyles};
    &::placeholder {
      ${placeholderFocusStyles}
    }
    &::selection {
      ${selectionStyles}
    }
  }
  &:disabled {
    cursor: not-allowed;
    ${disabledStyle}
  }
`

type InputProps = {
  status?: 'idle' | 'active' | 'error' | 'success',
  typography: string,
}

export function Input({
  typography = 'paragraph',
  status = 'idle',
  ...props
}: InputProps) {
  return (
    <StyledInput
      {...{ typography, ...props }}
      variant={status}
      letterSpacing={3}
    />
  )
}
