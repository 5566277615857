//@flow
import * as React from 'react'
import { Flex, Box } from '../atoms/cards'
import { Text } from '../atoms/text/text-new'
import { Button } from '../atoms/buttons/button-new/button'

type AddressBoxProps = { ...mixed }

export function AddressBox(props: AddressBoxProps) {
  return (
    <Box {...props}>
      <Text typography="heading4" as="h2">
        B&W Damp & Timber Preservation Ltd.
      </Text>
      <Box mt={0}>
        <Text typography="paragraph">
          Dormer House <br /> Carr Lane, Poringland, Norwich <br /> NR14 7LT
        </Text>
      </Box>
    </Box>
  )
}
