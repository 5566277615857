//@flow
declare var grecaptcha: { reset: () => void }

import * as React from 'react'
import styled from 'styled-components'
import { Button } from '../atoms/buttons/button-new/button'
import Recaptcha from 'react-google-recaptcha'
import { Text } from '../atoms/text/text-new'
import { NotificationContext } from '../../shared/context'
import { BreakpointContext } from '../../shared/context'

import {
  Formik,
  Field,
  Form as FormikForm,
  ErrorMessage as FormikError,
} from 'formik'

import MaskedInput from 'react-text-mask'
import * as Yup from 'yup'

import { Box, Flex } from '../atoms/cards'

import { Input } from '../atoms/forms/input'
import Label from '../atoms/forms/label'

const mobileMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
]

const phoneMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const encode = (obj: { [string]: mixed }): string =>
  Object.entries(obj)
    .map(
      ([key, val]) =>
        `${
          typeof val === 'string'
            ? `${encodeURI(key)}=${encodeURI(val)}`
            : val !== null && typeof val === 'object'
            ? //$FlowFixMe
              `${encode(val)}`
            : ''
        }`,
      ''
    )
    .join('&')

const contactFormSchema = Yup.object().shape({
  fullname: Yup.string()
    .required('Please enter your name')
    .min(2, 'Please enter your name')
    .max(
      70,
      'Seems a little long, can you shorten it to something less formal?'
    ),
  email: Yup.string()
    .email(`Please enter a valid email`)
    .required(`Please enter your email`),
  tel: Yup.string()
    .required(`Please enter a telephone number`)
    .min(9, 'Please enter a full telephone number')
    .max(13, 'The telephone number you entered seems too long.')
    .matches(/^[0|1]\d*/, 'Please enter only digits, starting with 0 or 1'),
  'g-recaptcha-response': Yup.string().required(
    'Please complete the Recaptcha'
  ),
})

const Form = styled(FormikForm)`
  position: relative;
`

const ERROR_HEX = 'modes.brick.primaryShades.40'

const Validation = (msg, props) => (
  <Label color={ERROR_HEX} {...props}>
    {msg}
  </Label>
)

function ContactForm(props: { name: string }) {
  const { addNotification } = React.useContext(NotificationContext)
  const breakpoint = React.useContext(BreakpointContext)
  return (
    <Box
      bg={'primaryShades.0'}
      boxShadow="p2"
      minHeight={11}
      borderRadius={3}
      {...props}
    >
      <Text typography="heading2" textAlign={['center', 'left']}>
        Get a callback
      </Text>
      <Text typography="paragraph" textAlign={['center', 'left']}>
        Complete the form below with a brief message and a member of the B&W
        team will be in touch as soon as possible.
      </Text>
      <Box mt={5}>
        <Formik
          initialValues={{
            fullname: '',
            tel: '',
            email: '',
            message: '',
            'g-recaptcha-response': '',
            'bot-field': '',
          }}
          validationSchema={contactFormSchema}
          onSubmit={({ tel, ...values }, { setSubmitting, resetForm }) => {
            const body = {
              'form-name': props.name,
              tel: tel.replace(/\s/g, ''),
              ...values,
            }
            fetch('/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode(body),
            })
              .catch(error =>
                addNotification({
                  type: 'toast',
                  body: {
                    variant: 'error',
                    heading: `There was an error sending your message`,
                    message: (
                      <>
                        Please try again, or call our office.
                        <br />
                        {error}
                      </>
                    ),
                  },
                })
              )
              .finally(() => {
                addNotification({
                  type: 'toast',
                  body: {
                    variant: 'success',
                    heading: `Thanks for your message!`,
                    message: (
                      <>
                        Our team has been notified, you can expect a call soon.
                      </>
                    ),
                  },
                })
                fetch('/.netlify/functions/sms-notification', {
                  method: 'POST',
                  body: JSON.stringify(body),
                }).then(response => console.log(response))
                resetForm()
                grecaptcha.reset()
                setSubmitting(false)
              })
          }}
          handleSubmit={e => e.preventDefault()}
        >
          {({ values, errors, touched, setFieldValue, isValid }) => {
            const getInputStatus = name => {
              try {
                if (Yup.reach(contactFormSchema, name)) {
                  if (errors[name] && touched[name]) {
                    return 'error'
                  }
                  if (errors[name] && (values[name] || touched[name])) {
                    return 'active'
                  }
                  if (values[name]) {
                    return 'success'
                  }
                  return 'idle'
                }
              } catch {
                if (values[name] && touched[name]) {
                  return 'success'
                }
                if (values[name] || touched[name]) {
                  return 'active'
                }
                return 'idle'
              }
            }

            return (
              <Form
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                data-netlify-recaptcha="true"
                name={props.name}
              >
                {/* *** BOT FIELD *** */}

                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out:
                    <Field name="bot-field" />
                  </label>
                </p>

                {/* *** FORM *** */}
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  position="relative"
                  maxWidth={[11, 12]}
                >
                  <Field
                    name="fullname"
                    render={({ field }) => {
                      const status = getInputStatus(field.name)
                      return (
                        <>
                          <Label {...{ status }} htmlFor="fullname">
                            Name
                          </Label>
                          <Input
                            {...{ ...field, status }}
                            id="fullname"
                            placeholder="Enter your name"
                            width="100%"
                          />
                        </>
                      )
                    }}
                  />
                  <Box alignSelf="flex-end" as="span">
                    <FormikError
                      name="fullname"
                      render={Validation}
                    ></FormikError>
                  </Box>
                </Flex>
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  mt={[1]}
                  maxWidth={[11, 12]}
                >
                  <Field
                    name="email"
                    render={({ field }) => {
                      const status = getInputStatus(field.name)
                      return (
                        <>
                          <Label {...{ status }} htmlFor="email">
                            Email
                          </Label>
                          <Input
                            {...{ ...field, status }}
                            id="email"
                            placeholder="e.g. email@example.com"
                          />
                        </>
                      )
                    }}
                  />
                  <Box alignSelf="flex-end" as="span">
                    <FormikError name="email" render={Validation}></FormikError>
                  </Box>
                </Flex>
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  mt={1}
                  maxWidth={[11, 12]}
                >
                  <Field
                    name="tel"
                    render={({ field }) => {
                      const status = getInputStatus(field.name)
                      return (
                        <>
                          <Label {...{ status }} htmlFor="contactTel">
                            Contact number
                          </Label>
                          <Input
                            {...{ ...field, status }}
                            as={MaskedInput}
                            mask={
                              field.value.match(/^07/) ? mobileMask : phoneMask
                            }
                            id="tel"
                            placeholder="0xxx xxx xxx"
                            inputMode="numeric"
                            guide={false}
                            type="text"
                          />
                        </>
                      )
                    }}
                  />
                  <Box alignSelf="flex-end" as="span">
                    <FormikError name="tel" render={Validation}></FormikError>
                  </Box>
                </Flex>
                <Flex
                  position="relative"
                  flexDirection="column"
                  alignItems="flex-start"
                  mt={[1]}
                  maxWidth={[12, 12, 12, 13]}
                >
                  <Field
                    name="message"
                    render={({ field }) => {
                      const status = getInputStatus(field.name)
                      return (
                        <>
                          <Label {...{ status }} htmlFor="message">
                            How can we help?
                          </Label>
                          <Input
                            as="textarea"
                            {...{ ...field, status }}
                            height={[7, 7, 7, 7, 8]}
                            minHeight={[6]}
                            maxHeight={[12]}
                            minWidth="100%"
                            maxWidth="100%"
                            id="message"
                            type="textarea"
                          />
                        </>
                      )
                    }}
                  />
                </Flex>
                <Flex flexDirection="column" alignItems="flex-start" mt={[3]}>
                  <Recaptcha
                    alignSelf={['center', 'flex-start']}
                    onChange={res => {
                      setFieldValue('g-recaptcha-response', res)
                    }}
                    size={breakpoint > 0 ? 'normal' : 'compact'}
                    sitekey="6LdmurUUAAAAAHlCeVQpe05GRm5zOXiC5aiQxr0u"
                  />
                  <FormikError
                    name="g-recaptcha-response"
                    render={m => Validation(m, { mt: 1 })}
                  ></FormikError>
                  <Button
                    alignSelf={['center', 'flex-start']}
                    mt={[6, 5, 4]}
                    type="submit"
                    typography="ui"
                    buttonStyle="solid"
                    variant={isValid ? 'submit-success' : 'submit'}
                    px={8}
                  >
                    Submit
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}

export default ContactForm
